<template>
    <!-- 设置微信推送弹窗 -->
    <el-dialog
        :title="username + '的订阅信息'"
        :visible.sync="dialogTableVisible"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="1020px"
        class="subscription-dialog"
    >
        <div class="table_div">
            <el-table
                border
                stripe
                :data="boxList"
                style="width: 961px"
                row-key="id"
                :cell-style="{
                    textAlign: 'center',
                    alignItems: 'baseline',
                    padding: '10px 0',
                }"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                    textAlign: 'center',
                    padding: '12px 0',
                }"
                :tree-props="{ children: 'children' }"
            >
                <el-table-column
                    prop="index"
                    label="序号"
                    width="100"
                ></el-table-column>
                <el-table-column
                    prop="blno"
                    label="提单号"
                    width="200"
                ></el-table-column>
                <el-table-column
                    prop="vesselsName"
                    label="船名"
                    width="180"
                ></el-table-column>
                <el-table-column
                    prop="voyage"
                    label="航次"
                    width="120"
                ></el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    width="180"
                ></el-table-column>
                <el-table-column
                    prop="createTime"
                    label="订阅时间"
                    width="180"
                ></el-table-column>
                <div slot="empty">
                    <el-empty :image-size="200"></el-empty>
                </div>
            </el-table>
        </div>
        <div class="pagination_div">
            <el-pagination
                background
                :hide-on-single-page="pageTotal < 10"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="sizes,prev, pager, next"
                :total="pageTotal"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                :current-page.sync="pagePosition"
            ></el-pagination>
        </div>
    </el-dialog>
</template>
<script>
import { queryBoxList } from "@/api/tracking";
import { apiCallback } from "../../../../../utils";
export default {
    data() {
        return {
            test: "测试信息",
            username: "",
            userid: 0,
            pageTotal: 0,
            pageSize: 10,
            pagePosition: 1,
            startRow: 1,
            totalBoxList: [], //存放原始数组用于筛选
            boxList: [], //展示用的列表数据
            dialogTableVisible: false,
        };
    },
    components: {},
    mounted() {},
    methods: {
        init(username, userid) {
            this.username = username;
            this.userid = userid;
            this.pageTotal = 0;
            this.pageSize = 10;
            this.pagePosition = 1;
            this.startRow = 1;
            this.getBoxListByPage();
            this.dialogTableVisible = true;
        },
        //修改分页的pagesize
        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getBoxListByPage();
        },
        getBoxListByPage() {
            var that = this;
            var data = {
                code: this.$store.state.other.systemKey,
            };
            data.pageNum = this.pagePosition;
            data.pageSize = this.pageSize;
            queryBoxList(data)
                .then(function (response) {
                    const data = apiCallback(response);
                    var list = data.list;
                    if (list === null) {
                        that.totalBoxList = [];
                        that.boxList = [];
                    } else {
                        that.totalBoxList = list;
                        that.pageTotal = data.total;
                        that.startRow = data.startRow;
                        that.pagePosition = data.pageNum;
                        // console.log(that.totalBoxList)
                    }
                    that.dealList(that.totalBoxList);
                })
                .catch((err) => {
                    console.error(err);
                    // that.$message.error(err);
                });
        },
        dealList(list) {
            this.boxList.splice(0, this.boxList.length);
            list.forEach((item) => {
                var data = {};
                data = this.dealListOne(item);
                if (item.soncount > 1) {
                    var children = this.dealListTwo(item);
                    data.children = children;
                }
                this.boxList.push(data);
            });
            // console.log(this.boxList)
        },
        dealListOne(item) {
            var data = {};
            data.index = this.tableIndex(this.boxList.length); //序号
            data.id = item.id; //id
            data.blno = item.blno; //提单号
            data.vesselsName = item.vesselsName; //船名
            data.voyage = item.voyage; //航次
            data.remark = item.remark; //备注
            data.createTime = item.createTime;
            return data;
        },
        dealListTwo(list) {
            var children = [];
            list.sonlist.forEach((item) => {
                var data = {};
                if (item.blno != list.blno) {
                    data.index = "";
                    data.id = item.id; //id
                    data.blno = item.blno; //提单号
                    data.vesselsName = list.vesselename; //船名
                    data.voyage = list.voyage; //航次
                    data.remark = item.remark; //备注
                    data.createTime = item.createTime;
                    children.push(data);
                }
            });
            return children;
        },
        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },

        closeDialog() {
            this.dialogTableVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
.subscription-dialog{
  &.el-dialog__wrapper{
    width: 100%;
  }
}


.table_div {
  min-height: 16rem;
}

.pagination_div {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
