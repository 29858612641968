<template>
    <el-dialog
        title="移交管理员"
        :visible.sync="dialogTableVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="360px"
    >
        <div>
            <el-select
                v-model="userName"
                placeholder="请选择移交对象"
                @change="handleSelect"
                style="width: 320px"
            >
                <el-option
                    v-for="item in colleagueDate"
                    :key="item.colleagueName"
                    :label="item.colleagueName"
                    :value="item.colleagueId"
                ></el-option>
            </el-select>
        </div>
        <div class="button">
            <el-button type="info" plain @click="closeDialog">取消</el-button>
            <el-popconfirm
                title="是否确定移交管理员？"
                confirm-button-text="确定移交"
                cancel-button-text="取消移交"
                placement="right"
                style="margin-left: 10px"
                @confirm="turnManage"
            >
                <el-button
                    slot="reference"
                    type="primary"
                    plain
                    :disabled="canSubmit"
                >
                    移交
                </el-button>
            </el-popconfirm>
        </div>
    </el-dialog>
</template>

<script>
import { transferAdminRole } from "@/api";

export default {
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            colleagueDate: [],
            userName: "",
            selectColleagueId: 0,
            canSubmit: true,
        };
    },
    components: {},
    mounted() {},
    methods: {
        init(list, cid) {
            this.companyId = cid;
            this.listDeal(list);
            this.userName = "";
            this.selectColleagueId = 0;
            this.canSubmit = true;
            this.dialogTableVisible = true;
        },
        listDeal(list) {
            this.colleagueDate = [];
            list.forEach((item) => {
                if (item.companyRole == "普通员工") {
                    let data = {};
                    data.colleagueName = item.userName;
                    data.colleagueId = item.id;
                    this.colleagueDate.push(data);
                }
            });
        },
        //移交管理员
        turnManage() {
            var that = this;
            var data = {};
            data.uid = this.$store.state.user.userId;
            data.cid = this.companyId;
            data.uid2 = this.selectColleagueId;
            transferAdminRole(data)
                .then(() => {
                    that.$message.success("移交成功");
                    that.$emit("isTurnOver");
                    that.closeDialog();
                })
                .catch((err) => console.error(err));
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
        handleSelect(e) {
            this.selectColleagueId = e;
            this.canSubmit = false;
        },
    },
};
</script>
<style lang="stylus">

.button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
